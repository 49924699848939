// import Vue from "vue";
// import Components from "./components";
import "./scss/app.scss";
import "./js/main"

// Font Awesome Icons
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { faFacebook, faTwitter, faInstagram, faLinkedin, faYoutube, faVimeo} from '@fortawesome/free-brands-svg-icons'
// import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

library.add( faAngleRight, faAngleLeft, faFacebook, faTwitter, faInstagram, faLinkedin, faYoutube, faVimeo)

dom.watch()


// Animate On Scroll Options
// https://github.com/michalsnik/aos
import AOS from 'aos';
AOS.init({duration: 800});


// Show made by Spaceray Creative banner.
if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
    var n = ["\n %c Made with ♥ by Spaceray Creative %c %c %c https://www.spaceraycreative.com/ \n\n", "color: #a0d39d; background: #0b4061; padding:5px 0;", "background: #4c4c4d; padding:5px 0;", "background: #4c4c4d; padding:5px 0;", "color: #fff; background: #1c1c1c; padding:5px 0;"];
    window.console.log.apply(console, n)
} else
    window.console && window.console.log("Made with love ♥ by Spaceray Creative - https://www.spaceraycreative.com/")


// Vue.config.productionTip = false;
//
// new Vue({
//   el: "#app",
//   delimiters: ["${", "}"]
// });
